import React, { useEffect, useState } from "react";
import Modal from "components/Layout/Modal";
import Card from "components/Layout/Card";
import Loader from "components/Layout/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  profileDetailsOfUsers,
  setIsEditDataOfProfile,
  setIsEditUserProfile,
} from "store/slices";
import { objectToFormData } from "utils/helpers";
import EditDetailsOfAllUsers from "./EditDetailsOfAllUsers/EditDetailsOfAllUsers";
import ViewDetailsOfAllUsers from "./ViewDetailsOfAllUsers";
import "./UpdateUserDetailsPopup.scss";

const UpdateUserDetailsPopup = ({ updatedList }) => {
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.global);
  const { isEditDataOfProfile } = reduxData || {};
  const [loadData, setLoadData] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [isEditDetails, setIsEditDetails] = useState(false);

  const fetchProfileDetails = async () => {
    setLoadData(true);
    const response = await dispatch(
      profileDetailsOfUsers(objectToFormData({ id: isEditDataOfProfile?.id }))
    );
    setProfileData(response?.data || {});
    setLoadData(false);
  };
  useEffect(() => {
    if (!updatedList?.user_id && isEditDataOfProfile?.id) {
      fetchProfileDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      size={"lg"}
      onHide={() => {
        dispatch(setIsEditUserProfile(false));
        dispatch(setIsEditDataOfProfile(null));
      }}
    >
      <div
        className="cps-20 cpe-20 cmt-10 cpb-20 overflow-x-hidden overflow-y-auto"
        style={{ maxHeight: "700px" }}
      >
        {loadData ? (
          <Card className="pt-5 pb-5">
            <Loader size="md" />
          </Card>
        ) : (
          <>
            {isEditDetails ? (
              <EditDetailsOfAllUsers
                profileData={profileData}
                setIsEditDetails={setIsEditDetails}
              />
            ) : (
              <ViewDetailsOfAllUsers
                profileData={profileData}
                setIsEditDetails={setIsEditDetails}
                isEditDetails={isEditDetails}
                updatedList={updatedList}
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default UpdateUserDetailsPopup;
